.submissions {
  transform: rotate(0);
  padding-bottom: 15px;

  &__loading {
    width: 150px;
    height: 150px;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  &__title {
    padding: 16px 12px;

    @include media-breakpoint-down(sm) {
      padding: 16px 24px;
    }
  }

  &__list {
    max-height: calc(100% - 47px);
    overflow-y: auto;
    padding: 0 12px;
    scrollbar-width: 7px;
    scrollbar-track-color: $lightGray;

    @include media-breakpoint-down(sm) {
      padding: 0 24px;
    }
  }

  &__list::-webkit-scrollbar {
    width: 7px;
  }

  &__list::-webkit-scrollbar-thumb {
    background-color: $lightGray;
    border-radius: 10px;
  }

  &__list-by-date {
    padding-left: 0;
  }

  &__date {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: $textGray;
    margin: 16px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  &__return {
    position: fixed;
    width: 36px;
    height: 36px;
    right: 24px;
    bottom: 14px;
    background-color: $dark;
    border: 1px solid $lightGray;
    border-radius: 50%;
    opacity: 0.8;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      transform: rotate(180deg);
    }
  }
}
