.sidebar {
  position: relative;
  width: 300px;
  display: flex;
  flex-direction: column;
  margin: 63px auto 0px;
  padding: 0;
  z-index: 101;

  &__loading {
    width: 100px;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  &__title.title {
    margin-bottom: 16px;
  }

  &__list {
    flex-grow: 1;
    margin: 0;
    padding: 0 25px;
    overflow-y: auto;
  }

  &__list::-webkit-scrollbar {
    width: 5px;
  }

  &__list::-webkit-scrollbar-thumb {
    background-color: $lightGray;
    border-radius: 10px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: 24px;
    border-top: 1px solid $lightGray;

    @include media-breakpoint-down(sm) {
      padding: 24px 16px;
    }

    @include media-breakpoint-down(xl) {
      padding: 24px;
    }
  }

  @include media-breakpoint-down(sm) {
    width: 210px;
    margin: 69px 0 0;
    margin-left: calc((100% - 512px) / 2);
  }

  @include media-breakpoint-down(md) {
    margin-left: calc((100% - 690px) / 2);
  }

  @include media-breakpoint-down(lg) {
    position: static;
    margin: 0;
  }

  @include media-breakpoint-down(xl) {
    position: static;
    width: 255px;
  }
}
