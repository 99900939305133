.email-list {
  border-radius: 8px;
  background-color: $dark;
  border: 1px solid $lightGray;
  padding: 8px 8px 12px 8px;
  transition: 0.5s;
  transition: visibility none;
  visibility: hidden;
  opacity: 0;
  box-sizing: border-box;
  z-index: 10;

  form {
    position: relative;
  }

  &__input {
    width: 100%;
    height: 32px;
    background: $lightGray;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 8px;
    border: 0;

    font-size: 14px;
    line-height: 18px;
    color: $textSecondary;

    &:focus {
      outline: none;
    }
  }

  &__submit {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 4px;
    right: 8px;
    border: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;

    svg path {
      transition: 0.4s;
    }

    @include media-breakpoint-down(lg) {
      &:hover {
        svg path {
          stroke: $hoverGray;
        }
      }
    }
  }

  &__list {
    padding: 0 8px;
    margin: 11px 0 0 0;
    list-style: none;

    font-size: 14px;
    text-align: center;
  }

  &__item {
    height: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      position: relative;
      width: 210px;
      display: block;
      font-size: 14px;
      line-height: 18px;
      color: $textSecondary;
      overflow-x: hidden;
      white-space: nowrap;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 25px;
        z-index: 2;
        background: linear-gradient(
          270deg,
          $dark 39.31%,
          rgba(33, 37, 38, 0) 100%
        );
      }
    }

    img {
      cursor: pointer;
      transition: 0.4s;

      &:hover {
        transform: rotate(90deg);
      }
    }
  }

  &__error {
    display: block;
    font-size: 12px;
    color: $red;
    padding-left: 8px;
    margin-top: 5px;
    margin-bottom: -10px;
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }
}
