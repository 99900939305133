.burger-menu {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  visibility: hidden;

  .sidebar {
    transform: translateX(-100vw);
    transition: 0.4s;
  }

  .footer {
    opacity: 0;
    display: flex;
  }

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(28, 31, 33, 0.6);
    backdrop-filter: blur(3px);
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
    transition: visibility none;
  }

  &.active {
    visibility: visible;
    transform: translate(0);

    .sidebar {
      transform: none;
    }

    .footer {
      opacity: 1;
    }

    .burger-menu__background {
      opacity: 1;
      visibility: visible;
    }
  }
}
