html {
  scroll-behavior: smooth;
}

body {
  height: 100vh;
  padding: 0 10px;
  color: $textPrimary;
  font-family: 'DM Sans', sans-serif;
  background-color: #1c1f21;
  overflow: hidden;
}

#root {
  height: 100%;
}

.container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;

  @include media-breakpoint-down(sm) {
    width: 512px;
  }

  @include media-breakpoint-down(md) {
    width: 690px;
  }

  @include media-breakpoint-down(lg) {
    width: 930px;
  }

  @include media-breakpoint-down(xl) {
    width: 1110px;
  }
}
