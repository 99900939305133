.submission {
  max-height: 51px;
  margin-top: 12px;
  margin-bottom: 12px;
  background: #2f3536;
  border-radius: 8px;
  list-style: none;
  overflow: hidden;
  // super hack for safary
  transform: translateZ(0);
  transition: max-height 0.3s ease-in-out;
  will-change: max-height;

  &__header {
    height: 51px;
    display: flex;
    align-items: center;
    padding: 0 16px 0 11.5px;
    cursor: pointer;

    &-extra-padding {
      padding-left: 30.5px;
    }
  }

  &__expand {
    width: 24px;
    height: 24px;
    border: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
  }

  &__arrow {
    transition: 0.4s;
  }

  &__info {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    margin-left: auto;

    @include media-breakpoint-down(sm) {
      flex-direction: row;
      gap: 16px;
    }
  }

  &__delete {
    font-size: 12px;
    line-height: 16px;
    color: $red;
    padding: 0;
    border: 0;
    background-color: transparent;
    transition: 0.4s;
    transition: visibility none;
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      &:hover {
        color: $hoverRed;
      }
    }

    @include media-breakpoint-down(xl) {
      visibility: hidden;
      opacity: 0;
    }
  }

  &__time {
    font-size: 12px;
    line-height: 16px;
    color: #868686;
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 0 24px 16px 56px;

    @include media-breakpoint-down(md) {
      flex-direction: row;
    }
  }

  &.active .submission__arrow {
    transform: rotate(180deg);
  }

  &:hover .submission__delete {
    opacity: 1;
    visibility: visible;
  }
}
