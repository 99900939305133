$lightGray: #2f3536;
$textGray: #868686;
$hoverGray: #bfbfbf;

$textPrimary: #ffffff;
$textSecondary: #f3f3f3;

$dark: #212526;

$red: #dc425e;
$hoverRed: #ff0a36;
