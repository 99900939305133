.header {
  position: relative;
  height: 33px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;

  &__logo {
    display: none;

    @include media-breakpoint-down(lg) {
      margin-left: calc((210px - 170px) / 2);
      height: 20px;
      display: block;
    }

    @include media-breakpoint-down(xl) {
      height: 26px;
      margin-left: calc((255px - 220px) / 2);
    }
  }

  &__burger {
    width: 24px;
    height: 24px;
    display: block;
    position: relative;
    z-index: 101;
    cursor: pointer;

    span {
      position: absolute;
      display: block;
      width: 18px;
      height: 2px;
      border-radius: 8px;
      background-color: $textSecondary;
      transition: 0.5s;

      &:first-child {
        top: 7px;
        left: 3px;
      }

      &:last-child {
        top: 15px;
        left: 3px;
      }
    }

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__account {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    cursor: pointer;
    z-index: 101;

    &.active {
      .header__arrow {
        transform: rotate(180deg);
      }
    }
  }

  &__email {
    max-width: 225px;
    font-size: 14px;
    line-height: 18px;
    color: $textSecondary;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__arrow {
    width: 24px;
    height: 24px;
    transition: 0.4s;
  }

  &__account-menu {
    position: absolute;
    top: 25px;
    right: 0;
    width: 170px;
    transition: 0.5s;
    transition: visibility none;
    visibility: hidden;
    opacity: 0;
    z-index: 101;

    &.active {
      visibility: visible;
      opacity: 1;
    }
  }

  &__account-item {
    width: 100%;
    height: 40px;
    padding: 11px 16px;
    background-color: $dark;
    border: 1px solid $lightGray;
    border-radius: 8px;
    list-style: none;
    cursor: pointer;
    box-sizing: border-box;

    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.015em;
    color: $textSecondary;
  }

  &.active {
    .header__burger {
      span {
        width: 25px;

        &:first-child {
          top: 2px;
          transform-origin: left;
          transform: rotate(45deg);
        }

        &:last-child {
          top: 20px;
          transform-origin: left;
          transform: rotate(-45deg);
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    height: 40px;

    &__logout {
      font-size: 14px;
    }
  }

  @include media-breakpoint-down(lg) {
    margin: 24px 0;
  }
}
