.url {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background-color: $lightGray;
  padding: 2px 0 2px 3px;
  box-sizing: border-box;

  &__button {
    position: relative;
    width: 71px;
    height: 32px;
    flex-shrink: 0;

    .url__text {
      font-size: 12px;
      color: $textSecondary;
      background-color: $lightGray;
    }

    &::before {
      transition: 0.8s;
    }

    @include media-breakpoint-down(sm) {
      width: 87px;

      .url__text {
        font-size: 14px;
      }
    }
  }

  &__link {
    white-space: nowrap;
    height: 100%;
    padding-top: 7px;
    overflow-x: scroll;
    scrollbar-width: none;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 18px;
    color: $textSecondary;
  }

  &__link::-webkit-scrollbar {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    width: 51px;
    height: 100%;
    top: 0;
    right: -1px;
    z-index: 2;
    border-radius: 8px;
    background: linear-gradient(
      270deg,
      $lightGray 39.31%,
      rgba(47, 53, 54, 0) 100%
    );
  }
}
