.grid {
  display: grid;
  grid-template-columns: 100%;

  .column:first-child {
    display: none;

    @include media-breakpoint-down(lg) {
      display: block;
    }
  }

  @include media-breakpoint-down(sm) {
    grid-template-columns: 512px;
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: 690px;
  }

  @include media-breakpoint-down(lg) {
    grid-template-columns: 210px 690px;
    grid-column-gap: 30px;
  }

  @include media-breakpoint-down(xl) {
    grid-template-columns: 255px 825px;
  }
}
