.settings {
  margin-bottom: 25px;
  padding-bottom: 16px;

  &__loading {
    width: 116px;
    margin-top: 16px;
    margin-left: 50%;
    transform: translateX(-50%);

    @include media-breakpoint-down(sm) {
      width: 64px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 16px;

    .title {
      max-width: 50%;
      display: flex;
      padding: 0;
    }

    @include media-breakpoint-down(xl) {
      padding: 16px 24px;
    }
  }

  &__name {
    display: block;
    margin-right: 5px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  &__delete {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: $red;
    background-color: transparent;
    padding: 0;
    border: 0;
    cursor: pointer;
    transition: 0.4s;

    @include media-breakpoint-down(lg) {
      &:hover {
        color: $hoverRed;
      }
    }
  }

  &__body {
    padding: 0 12px;

    @include media-breakpoint-down(xl) {
      padding: 0 24px;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: row;
      justify-content: space-between;
      gap: 0;
    }
  }

  &__url {
    width: 100%;
    height: 36px;

    @include media-breakpoint-down(sm) {
      width: 251px;
    }

    @include media-breakpoint-down(md) {
      width: 360px;
    }

    @include media-breakpoint-down(lg) {
      width: 368px;
    }

    @include media-breakpoint-down(xl) {
      width: 487px;
    }
  }

  &__email {
    position: relative;
    height: 36px;

    @include media-breakpoint-down(sm) {
      width: 225px;
    }

    @include media-breakpoint-down(md) {
      width: 266px;
    }
  }

  &__redirect {
    position: relative;
    width: 100%;
    height: 36px;
  }
}
