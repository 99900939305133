.header-field {
  max-width: calc(100% - 100px);
  position: relative;
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;

  &:nth-child(2) {
    margin-left: 19.5px;
  }

  &__title {
    font-size: 12px;
    line-height: 16px;
    color: #878787;
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-width: none;
  }

  &__content {
    font-size: 14px;
    line-height: 19px;
    color: $textPrimary;
    margin: 0;
    overflow-x: scroll;
    scrollbar-width: none;
  }

  &__title::-webkit-scrollbar,
  &__content::-webkit-scrollbar {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    width: 86px;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 2;
    background: linear-gradient(
      269.8deg,
      $lightGray 36.2%,
      rgba(47, 53, 54, 0.45) 99.82%
    );
  }

  @include media-breakpoint-down(md) {
    width: 220px;
    flex-grow: inherit;
  }

  @include media-breakpoint-down(xl) {
    width: 282px;
  }
}
