.field {
  width: 100%;
  margin-top: 4px;
  margin-bottom: 8px;
  margin-right: 24px;

  &__title {
    font-size: 12px;
    line-height: 16px;
    color: #878787;
    word-wrap: break-word;
  }

  &__content {
    font-size: 14px;
    line-height: 19px;
    color: $textPrimary;
    word-wrap: break-word;
    margin: 0;
  }

  @include media-breakpoint-down(md) {
    width: 220px;
  }

  @include media-breakpoint-down(xl) {
    width: 282px;
  }
}
