@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/DMSans-Regular.ttf');
}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/DMSans-Medium.ttf');
}
