.footer {
  position: relative;
  display: none;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  padding-bottom: 24px;
  transition: 0.3s;
  z-index: 101;

  @include media-breakpoint-down(sm) {
    display: flex;
  }

  div {
    color: $textGray;
    font-size: 16px;
    margin-bottom: 12px;

    a {
      font-size: 700;
      color: #acacac;
      transition: 0.3s;

      &:hover {
        color: $textGray;
        text-decoration: none;
      }
    }
  }
}
