.block {
  background: $dark;
  border: 1px solid $lightGray;
  box-sizing: border-box;
  border-radius: 8px;
}

.title {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $textSecondary;
  padding: 16px 24px 0;
  margin: 0;
}

.gradient-button {
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
  position: relative;
  cursor: pointer;

  & > span {
    position: relative;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    color: $textSecondary;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    margin: 1px;
    background-color: $dark;
    box-sizing: border-box;
    border-radius: 8px;
    z-index: 2;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 8px;
    background: linear-gradient(90deg, #12c2e9, #c471ed, #ee3641, #12c2e9);
    background-size: 150% 100%;
    transition: 1.2s;
    z-index: 1;
  }

  &:hover {
    &::before {
      @include media-breakpoint-down(lg) {
        background-position: 300%;
      }
    }
  }
}

.center-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-with-header {
    height: calc(100% - 53px * 2);

    @include media-breakpoint-down(md) {
      height: calc(100% - 60px * 2);
    }

    @include media-breakpoint-down(lg) {
      height: calc(100% - 88px * 2);
    }
  }
}

.create-endpoint-button {
  width: 100%;
  max-width: 203px;
  height: 40px;
}

.notification {
  position: absolute;
  padding: 0 12px;
  height: 30px;
  top: -35px;
  left: 50%;
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: $textSecondary;
  background-color: $lightGray;
  border-radius: 8px;
  transform: translateY(100%) translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  transition: visibility none;

  &.active {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
    visibility: visible;
  }
}
