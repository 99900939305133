.endpoint {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 17px;
  margin-bottom: 2px;
  list-style: none;
  background: $dark;
  border-radius: 8px;
  transition: 0.4s;
  cursor: pointer;

  form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__name {
    width: 75%;
    position: relative;
    font-size: 14px;
    line-height: 18px;
    padding-left: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;

    &::before {
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      display: block;
      top: 6px;
      left: 0;
      transition: 0.4s;
      background-color: $lightGray;
      border-radius: 100%;
    }
  }

  &__edit {
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.015em;
    color: $textGray;
    visibility: hidden;
    opacity: 0;
    transition: 0.4s;
    transition: visibility none;

    @include media-breakpoint-down(lg) {
      &:hover {
        color: $hoverGray;
      }
    }
  }

  &__input {
    width: 100%;
    max-width: 85%;
    height: 18px;
    position: relative;
    font-size: 14px;
    line-height: 18px;
    padding: 0 16px;
    border: 0;
    color: $textPrimary;
    background-color: transparent;
    box-sizing: border-box;

    &:focus {
      outline: none;
    }

    &::selection {
      background-color: $textGray;
    }
  }

  &__save {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.015em;
    color: $textGray;
    background-color: transparent;
    transition: 0.4s;
    border: 0;
    padding: 0;
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      &:hover {
        color: $hoverGray;
      }
    }
  }

  &.active {
    cursor: inherit;
    background-color: $lightGray;

    .endpoint__name::before {
      background-color: $textSecondary;
    }

    .endpoint__edit {
      visibility: visible;
      opacity: 1;
    }
  }

  @include media-breakpoint-down(sm) {
    &:hover {
      .endpoint__name::before {
        background-color: $textSecondary;
      }

      .endpoint__edit {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
