.redirect {
  &__input {
    width: 100%;
    height: 100%;
    padding: 9px 24px 9px 16px;
    box-sizing: border-box;

    color: $textSecondary;
    font-size: 14px;
    line-height: 18px;

    background: $lightGray;
    border-radius: 8px;

    border: none;

    &:focus {
      outline: none;
    }
  }

  &__tip-button {
    position: absolute;
    top: 8px;
    right: 14px;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      g {
        path {
          transition: 0.4s;
          stroke: $hoverGray;
        }
      }
    }
  }

  &__tip {
    position: absolute;
    width: 220px;
    z-index: 100;

    opacity: 0;
    visibility: hidden;
    transition: 0.4s;

    top: 100%;
    right: 0;
    padding: 8px;

    color: $textPrimary;
    font-size: 12px;

    background: $lightGray;
    border: 1px solid $dark;
    border-radius: 8px;

    a {
      color: $textGray;
    }

    &.active {
      opacity: 1;
      visibility: visible;
      transition: 0.4s;
      transition: visibility none;
    }
  }
}
