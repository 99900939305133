@mixin media-breakpoint-down($breakpoint) {
  @if $breakpoint == sm {
    @media (min-width: 576px) {
      @content;
    }
  }

  @if $breakpoint == md {
    @media (min-width: 768px) {
      @content;
    }
  }

  @if $breakpoint == lg {
    @media (min-width: 992px) {
      @content;
    }
  }

  @if $breakpoint == xl {
    @media (min-width: 1200px) {
      @content;
    }
  }
}
