.login {
  width: 100%;
  max-width: 380px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;

  &-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__logo {
    position: absolute;
    top: 48px;
    left: 50%;
    width: 220px;
    transform: translateX(-50%);
  }

  &__title {
    margin: 0;
    margin-bottom: 40px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
  }

  &__input {
    width: 100%;
    height: 40px;
    border: 0;
    outline: none;
    border-radius: 8px;
    padding-left: 18px;
    color: $textSecondary;
    background-color: $lightGray;
    box-sizing: border-box;
    margin-bottom: 28px;
  }

  &__submit {
    width: 100%;
    height: 40px;
  }

  &__securedby {
    display: flex;
    align-items: center;
    margin-top: auto;
    height: 30px;
    text-decoration: none;

    span {
      font-size: 12px;
      color: $textGray;
      margin-right: 10px;
      transition: 0.4s;
    }

    &:hover {
      span {
        color: $hoverGray;
      }
    }
  }
}
