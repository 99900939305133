.email {
  &__loading {
    width: 30px;
    height: 30px;
  }

  &__button {
    width: 100%;
    height: 100%;

    div {
      position: relative;
      width: calc(100% - 32px);
      height: calc(100% - 2px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      margin-left: 1px;
      padding: 0 15px;

      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $textSecondary;
      background-color: $lightGray;
      z-index: 2;

      img {
        transition: 0.4s;
      }
    }

    &.active {
      div img {
        transform: rotate(180deg);
      }
    }
  }

  &__list {
    position: absolute;
    top: 44px;
    width: 100%;
  }
}
